<template>
  <div class="flex flex-col my-6 lg:px-64 sm:px-32 px-6">
    <h1 class="sm:text-4xl text-2xl pb-4 dark:text-gray-300">Our Story</h1>
    <div class="sm:text-xl text-lg text-justify dark:text-gray-300">
      It was the July of 2020. Almost half a year had passed since the global
      pandemic hit the world. Things did not look pleasent and people found
      themselves dragged back several steps, with little idea on what to do. We
      saw failing business models around us and a lack of trust and enthusiasm
      in the industry. Businesses fell apart and people were forced to work from
      home. More and more franchises were shutting down across cities. We
      witnessed commercial and industrial activity slowing down around us.
      <br />
      <br />
      Auraborus was fortunate to have started during these days. We tried to
      figure out why businesses were failing, and decided to use all the weak
      points that had dragged businesses down, and turn them to our advantage.
      We started as a wild entrepreneurial adventure in the July of 2020, aiming
      at the impossible. Auraborus was a small group, with one primary goal in
      mind; to improve our lives and the ones around us by making our little
      adventure a success.
      <br />
      <br />
      We aimed at consistency and building trust. Our earliest clients reached
      back to acknowledge that the solutions we had delivered to them were
      infact far beyond the requirements they had stated and had thus saved them
      a lot of trouble. We were empathatic and delivered solutions mapped to
      success, not just requirements.
      <br />
      <br />
      In a matter of a few months, Auraborus has managed to serve several
      clients within and outside Pakistan and continues to improve lives by
      delivering trust and value to all that reach out.
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
